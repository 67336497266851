$(() => {
  let origin = null;
  const LOGGED_IN_CHECK_TIMEOUT = 2000; // ms

  window.addEventListener('message', (msg) => {
    if (!origin) {
      origin = msg.origin;
    }

    try {
      const data = JSON.parse(msg.data);
      const setValue = (selector, value) => $(selector).val(value).trigger('input');

      setValue('[name="pin[name]"]', data['name']);
      setValue('[name="pin[content1]"]', data['instructions']);
      setValue('[name="pin[content2]"]', data['ingredients']);
      setValue('[name="pin[source]"]', data['source']);
      setValue('[name="file_url_cache"]', data['images']);
    } catch (e) {
      return; // sent data are not json
    }
  });

  window.reloadLogged = (url) => {
    const test = () => {
      $.get('/bookmarklet/check')
        .done(() => location.href = url)
        .fail(e => setTimeout(test, LOGGED_IN_CHECK_TIMEOUT));
    };

    test();
  };

  window.closeBookmarklet = () => {
    if (parent) {
      parent.postMessage(JSON.stringify({ type: 'close' }), origin);
    }
  };
});
