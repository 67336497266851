$(() => {
  $(window).on('resize shown.bs.modal', () => {
    const $modals = $('.modal.modal-centered');
    $modals.each(function(i, modal){
      const $modal = $(modal);
      const $modalContent = $modal.find('.modal-content');
      const margin = Math.max(0, ($modal.height() - $modalContent.height()) / 2);
      $modalContent.css('margin-top', margin);
    });
  });
});
