import '../src/legacyApplication/bookmarkletEvents';
import '../src/legacyApplication/bootstrap';
import '../src/legacyApplication/browserUpgradeInfo';
import '../src/legacyApplication/card';
import '../src/legacyApplication/centeredModal';
import '../src/legacyApplication/dismissibleAlerts';
import '../src/legacyApplication/euCookies';
import ImagePreview from '../src/legacyApplication/imagePreview';
import '../src/legacyApplication/jsSupport';
import '../src/legacyApplication/lazyImages';
import '../src/legacyApplication/legacyUrlsRedirect';
import * as Modal from '../src/legacyApplication/modal';
import '../src/legacyApplication/pagination';
import '../src/legacyApplication/repin';
import ResizableTextarea from '../src/legacyApplication/resizableTextarea';
import '../src/legacyApplication/scrollToTop';
import SelectboxCloud from '../src/legacyApplication/selectboxCloud';
import '../src/legacyApplication/social';
import '../src/legacyApplication/styles/main';

// import images
import '../images/icon-green.png';
import '../images/icon/apple-touch-icon.png';
import '../images/icon/favicon.png';
import '../images/icon/android-chrome-192x192.png';
import '../images/icon/android-chrome-512x512.png';
import '../images/icon/favicon-16x16.png';
import '../images/icon/favicon-32x32.png';
import '../images/icon/favicon.ico';
import '../images/icon/mstile-150x150.png';
import '../images/icon/safari-pinned-tab.svg';
import '../images/fb/f_logo_RGB-White_58.png';

window.Modal = Modal;
window.ImagePreview = ImagePreview;
window.SelectboxCloud = SelectboxCloud;
window.ResizableTextarea = ResizableTextarea;
