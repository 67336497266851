$(() => {
  const opts = {
    itemSelector: '.card',
    columnWidth: 294,
    gutter: 66,
    isFitWidth : true,
    transitionDuration: 0,
  };

  $('.card-wrapper').masonry(opts);
});
