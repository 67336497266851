export default class ResizableTextarea {
  constructor(textareas){
    $(textareas).each(this.init);
  }

  init (i, textarea) {
    const $textarea = $(textarea);

    const nl2br = text => (text || '').replace(/\n/g, '<br>');

    const stripTags = (html) => {
      const newLineTags = ['br'];
      const reg = new RegExp(`(<((${newLineTags.join('|')})[^>]*)>)`, 'ig');
      html = html.replace(reg, '\n');

      const tmp = $('<div>');
      return tmp.html(html).text();
    };

    if ($textarea.parent().find('.fake-textarea').length !== 0) { return; }

    const $div = $('<div>');
    $div.addClass('fake-textarea form-control');
    $div.attr('placeholder', $textarea.attr('placeholder'));
    $textarea.parent().append($div);
    $div.get(0).contentEditable = true;
    $div.html(nl2br($textarea.val()));

    $div.on('paste', (e) => {
      e.preventDefault();

      const text = e.originalEvent.clipboardData.getData('text/plain');
      //console.log('from clipboard', text);
      $textarea.val(text).trigger('input');
    });

    $div.on('input', () => {
      const text = stripTags($div.html());
      //console.log('input to div', text);
      $textarea.val(text);
    });

    $textarea.on('input propertychange', () => {
      const html = nl2br($textarea.val());
      //console.log('from textarea', html);
      $div.html(html);
    });
  }
}
