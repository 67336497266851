let openModalState = null;

const _show = (state) => {
  state.close = close.bind(window);
  $(state.target)
    .html(state.html)
    .on('hide.bs.modal', state.close)
    .modal('show');

  _handleTitle(state);
  openModalState = state;
};

const _handleUrl = (state) => {
  window.history.pushState(state, state.title, state.url);
};

const _handleTitle = (state) => {
  const $title = $('head title');
  if (state.title) { $title.text(state.title); }
};

const _handleAnalytics = (state) => {
  if (state.url && window.ga) {
    window.ga('send', 'pageview', state.url);
  }
};

const _close = () => {
  $(openModalState.target)
    .off('hide.bs.modal', openModalState.close)
    .modal('hide');

  openModalState = null;
};

export const show = (state) => {
  if (!window.history && !state.target && !state.html && !state.url) { return; }
  state.modal = true;
  _handleUrl(state);
  _show(state);
  _handleAnalytics(state);
};

export const close = (event) => {
  if (event != null) {
    event.preventDefault();
  }
  window.history.back();
};

$(() => {
  $(window).on('popstate', function(event) {
    const state = event.originalEvent.state || {};

    if (openModalState) { _close(); }
    if (state.modal) { _show(state); }

    _handleTitle(state);
    _handleAnalytics(state);
  });

  const title = $('head title').text();
  window.history.replaceState({title, url: window.location.pathname}, title);
});
