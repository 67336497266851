$(() => {
  $('.top-scroller').on('click', (e) => {
    $('html, body').animate({scrollTop: 0}, 600);
    return false;
  });

  $(window).on('scroll', () => {
    const fromTopPx = 200; // distance to trigger
    const scrolledFromtop = $(window).scrollTop();
    if (scrolledFromtop > fromTopPx) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }
  });
});
