$(() => {
  const match = location.href.match(/#!\/(recipe|user|cookbook)\/(\d+)/);
  if (match) {
    const type = {
      recipe: 'recept',
      cookbook: 'kucharka',
      user: ''
    }[match[1]];

    location.href = `/${type}/${match[2]}`.replace('//','/');
  }
});
