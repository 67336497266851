export default class ImagePreview {

  constructor(wrapper) {
    this.position = 0;
    this.imageUrls = [];
    this.$wrapper = $(wrapper);
    this.defaultImage = this.$wrapper.find('img').attr('src');
    this.$wrapper.get(0).imagePreview = this;
    this.fileImage = undefined;
    this.$fileImageInput = this.$wrapper.find('input[type=file]');
    this.$fileUrlCache = this.$wrapper.find('input[name=file_url_cache]');

    this.$fileImageInput.on('change', () => {
      this.loadFileImage();
    });

    this.$fileUrlCache.on('input', () => {
      this.updateImageUrls();
      this.render();
    });

    this.$wrapper.find('[data-file-upload]').on('click', event => {
      event.preventDefault();
      this.$fileImageInput.click();
    });

    this.$wrapper.find('[data-image-switch="next"]').on('click', event => {
      this.next();
    });

    this.$wrapper.find('[data-image-switch="prev"]').on('click', event => {
      this.prev();
    });

    this.updateImageUrls();
    this.render();
    this.$wrapper.trigger('initialized');
  }

  updateImageUrls() {
    const imageLinks = (this.$fileUrlCache.val() || '')
      .split(',')
      .map(url => (url || '').trim())
      .filter(x => !!x);

    this.imageLinks = jQuery.unique(imageLinks);
  }

  loadFileImage() {
    let files;
    if (this.$fileImageInput && this.$fileImageInput.get(0)) {
      ({ files } = this.$fileImageInput.get(0));
    }

    if (FileReader && files && files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.fileImage = fileReader.result;
        this.render();
      };

      return fileReader.readAsDataURL(files[0]);
    }
  }

  image() {
    if (this.fileImage) {
      return `url('${this.fileImage}')`;
    }

    if (this.imageLinks[this.position]) {
      return `url('${this.imageLinks[this.position]}')`;
    }
    if (this.defaultImage) {
      return `url('${this.defaultImage}')`;
    }
  }

  render() {
    if (this.imageLinks.length > 1) {
      this.$wrapper.find('.control').css('display', 'block');
      this.$wrapper.find('[data-file-upload]').css('display', 'none');
    } else {
      this.$wrapper.find('.control').css('display', 'none');
      this.$wrapper.find('[data-file-upload]').css('display', 'block');
    }

    if (this.imageLinks[this.position]) {
      this.$wrapper.find('[name="pin[image][remote_file_url]"]').val(this.imageLinks[this.position]);
    } else {
      this.$wrapper.find('[name="pin[image][remote_file_url]"]').val('');
    }

    this.$wrapper.find('.image-preview').css('background-image', this.image());
    if (this.image()) {
      this.$wrapper.removeClass('empty');
    } else {
      this.$wrapper.addClass('empty');
    }

    return this;
  }

  reset() {
    if (this.position !== 0) {
      this.position = 0;
      this.render();
    }

    return this;
  }

  next() {
    this.position += 1;
    if (this.position === this.imageLinks.length) {
      this.position = 0;
    }

    this.render();

    return this;
  }

  prev() {
    this.position -= 1;
    if (this.position === -1) {
      this.position = this.imageLinks.length;
    }

    this.render();

    return this;
  }
}
