const throttle = (timeout, fn) => {
  let lastTimeout = undefined;

  return () => {
    if (lastTimeout) { clearTimeout(lastTimeout); }
    return lastTimeout = setTimeout(fn, timeout);
  };
};

const loadMorePins = throttle(500, () => {
  const moreUrl = $('.pagination a.next-page').attr('href');
  const inLoadPoint = $(window).scrollTop() > ($(document).height() - $(window).height() - 60);

  if (moreUrl && inLoadPoint) {
    $('.pagination').html('');
    $.getScript(moreUrl);
  }
});

$(() => {
  $(window).on('scroll', () => loadMorePins());
  loadMorePins();

  $(window).on('pins.loaded', (event, data) => {
    if (!data) {
      return;
    }

    if (data.last && data.loaded) {
      loadMorePins();
    }
  });
});
