$(() => {
  const nav = navigator.userAgent.toLowerCase();

  let IEVersion = false;
  if (nav.indexOf('msie') !== -1) {
    IEVersion = parseInt(nav.split('msie')[1], 10);
  }

  let showUpgradeInfo = false;
  if (IEVersion !== false && IEVersion < 10) {
    showUpgradeInfo = true;
  }

  if (showUpgradeInfo) {
    $('.browser-upgrade-info').show();
  }
});
