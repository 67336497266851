const isElementVisibleChecker = () => {
  const $window = $(window);
  const viewBox = {
    top: $window.scrollTop(),
    left: $window.scrollLeft(),
    right: $window.scrollLeft() + $window.width(),
    bottom: $window.scrollTop() + $window.height(),
  };

  return (i, elem) => {
    const $elem = $(elem);
    const offset = $elem.offset();

    return offset.top < viewBox.bottom &&
           offset.top + $elem.height() > viewBox.top &&
           offset.left < viewBox.right &&
           offset.left + $elem.width() > viewBox.left;
  };
};

const changeVisibility = () => {
  $('[data-lazy-image].loading')
    .filter(isElementVisibleChecker())
    .each(function(i, elem){
      const $elem = $(elem);
      $elem.on('load', function() {
        $elem.removeClass('loading');
      });

      $elem.attr('src', $elem.data('lazy-image'));
    })
};

$(document).ready(changeVisibility);
$(document).on('pins.loaded scroll', changeVisibility);
$(window).on('resize', changeVisibility);
