/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() =>
  $(document).on('click', '*[data-repin-close]', function(event) {
    event.preventDefault();
    const $target = $(event.target);
    const $targetedPin = $target.parents('*[data-pin]');
    const pinId = $targetedPin.data('pin');

    $(`*[data-pin=${pinId}] *[data-repin-form]`).html('');
    $('.card-wrapper').masonry('reloadItems').masonry('layout');

    return false;
  })
);

// Change repin name
$(window).on('shown.bs.modal', (event) => {
  if ($(event.target).find('.repin-dialog').length === 0) { return; }

  const $repinName = $('.repin-dialog .repin-name');
  $repinName[0].contentEditable = true;
  $repinName.on('blur', () => $('.repin-dialog [name="pin[name]"]').val($repinName.text().trim()));
});
