const getUrl = () => window.location.href;

const sharePageOnTwitter = () => {
  if (!window.Twitter) { return; }
};

const sharePageOnFacebook = () => {
  if (!window.FB) { return; }

  FB.ui({
    method: 'share',
    href: getUrl()
  });
};

const sharePage = (social) => {
  switch (social.toLowerCase()) {
    case 'facebook': sharePageOnFacebook(); break;
    case 'twitter': sharePageOnTwitter(); break;
    default: console.error('Unknow social site');
  }

  return false;
};

window.Social = {sharePage};
