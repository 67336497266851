export default class SelectboxCloud {
  constructor(selectboxes) {
    return $(selectboxes).each(this.init);
  }

  init(i, selectbox) {
    const $selectbox = $(selectbox);
    const $targetInput = $($selectbox.data('selectbox-cloud'));
    const $targetNewInput = $($selectbox.data('selectbox-cloud-new'));

    const $options = $selectbox.find(".option");
    $options.removeClass('active');

    // Handling form reload
    $selectbox.find(`.option[data-option-value=\"${$targetInput.val()}\"]`)
      .addClass('active');

    const $newOption = $selectbox.find('.option-new');

    if ($targetNewInput.val() && !$targetInput.val()) {
      $newOption
        .addClass('active')
        .text($targetNewInput.val());
    }

    // Handling existing option
    $options.click(function() {
      const $option = $(this);
      $options.removeClass('active');
      $option.addClass('active');
      $targetInput.val($option.data('option-value') || '');
      $targetNewInput.val('');
    });

    // Handling new option
    return $newOption.on('input', function() {
      return $targetNewInput.val($(this).text().trim());
    });
  }
}
